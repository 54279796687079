<template>
  <section
    :id="activeSection"
    v-if="activeSection"
    class="-mt-28 pt-28"
    ref="activeSectionRef"
  >
    <section class="relative h-auto md:h-auto">
      <div
        class="relative h-96 md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2"
      >
        <img
          class="h-full w-full object-cover"
          :src="getImage(activeSection)"
          alt="Twarz młodej kobiety w zblieniu podczas zabiegu kosmetycznego."
        />
      </div>

      <div class="relative mx-auto max-w-7xl pb-4 pt-20 sm:py-20 lg:px-8">
        <div
          class="pl-4 pr-4 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32"
        >
          <h2
            v-html="getHeadingText(activeSection)"
            class="border-b border-papi-gold-200 text-center font-dm-serif-display text-2xl font-light tracking-wide text-papi-black-100 sm:text-4xl md:text-left lg:text-6xl"
          />

          <div class="mx-auto mt-14">
            <article
              class="flex flex-wrap rounded-sm bg-papi-gray-100 p-6 shadow md:p-8"
            >
              <p
                v-html="getDescriptionText(activeSection)"
                class="text-base font-light leading-7 text-papi-gray-300"
              />
            </article>
          </div>

          <section
            class="mt-14 text-base font-light text-papi-black-100"
          ></section>
        </div>
      </div>
    </section>
    <TreatmentMoreInfo />
    <QAndA />
    <!-- <PostTreatment /> -->
    <PostTreatmentSteps />
  </section>
</template>

<script>
import TreatmentMoreInfo from "@/components/TreatmentMoreInfo.vue";
import QAndA from "@/components/QAndA.vue";
// import PostTreatment from "@/components/PostTreatment.vue";
import PostTreatmentSteps from "@/components/PostTreatmentSteps";

export default {
  name: "Treatment",
  components: {
    TreatmentMoreInfo,
    QAndA,
    // PostTreatment,
    PostTreatmentSteps,
  },
  computed: {
    activeSection() {
      return this.$store.state.activeSection;
    },
  },
  methods: {
    getHeadingText(section) {
      const headingTextMap = {
        lashes_styling: "Stylizacja rzęs",
        non_standard_lashes_styling: "Niestandardowe metody stylizacji rzęs",
        lashes_lamination: "Laminacja rzęs",
        eyebrow_lamination: "Laminacja brwi",
        classic_henna: "Henna klasyczna brwi",
        powder_henna: "Henna pudrowa brwi",
        eyebrow_makeup: "Makijaż permanentny brwi",
        lip_makeup: "Makijaż permanentny ust",
        eyeliner_makeup: "Eyeliner permanentny",
        makeup_removal: "Usuwanie makijażu permanentnego",
        laser_remover: "Laser/ Remover",
        detox_peel: "Detox Peel",
        chemical_peeling: "Peelingi chemiczne",
        mesotherapy: "Mezoterapia igłowa",
        tissue_stimulator: "Stymulatory tkankowe",
        lip_modeling: "Modelowanie ust na płasko",
        lipolysis: "Lipoloza iniekcyjna",
        botulinum_toxin: "Toksyna botulinowa",
      };
      return headingTextMap[section] || "";
    },
    getDescriptionText(section) {
      const treatmentTextMap = {
        lashes_styling:
          "Często kobiety dwoją się i troją, aby uzyskać efekt głębszego spojrzenia i pełniejszych oczu. Niestety, mało która z nas została obdarzona z natury długimi i gęstymi rzęsami, które gwarantują nam pożądany efekt zaraz po przebudzeniu. Tusz, który nakładamy podczas porannego makijażu, często kruszy się i rozmazuje, psując stylizację.<br /><br /> Odpowiedzią na potrzeby kobiet stał się więc zabieg przedłużania i stylizacji rzęs, który mimo upływu lat wciąż cieszy się ogromną popularnością.",
        non_standard_lashes_styling:
          "Stylizacja rzęs to dziś absolutny must have niemal każdej kobiety. Piękne rzęsy nie tylko podkreślają tęczówkę, ale również dodają pewności siebie i optycznie powiększają oko. Obecne metody stylizacji pozwalają na osiągnięcie takich efektów, jakie tylko wymarzy sobie klientka - nawet jeśli wymagania są niestandardowe.",
        lashes_lamination:
          "Laminacja rzęs to innowacyjny zabieg, który polega na podkręceniu, wydłużeniu i przyciemnieniu rzęs naturalnych. Laminacja rzęs to zabieg trwały, który utrzymuje się przez wiele tygodni. Dzięki temu możesz cieszyć się pięknymi rzęsami bez konieczności codziennego stosowania kosmetyków.<br /><br /> Efektu takiego, jak laminacja rzęs nie da się uzyskać w domowym zaciszu tj. w przypadku maskary, ani też zalotki do rzęs.",
        eyebrow_lamination:
          "Laminacja brwi to zabieg, którego efektem są pięknie ukształtowane i podkreślone brwi. Jest to całkowicie bezpieczna oraz innowacyjna metoda, która cieszy się rosnącym zainteresowaniem wśród kobiet pragnących uzyskać piękne, zdrowe ukształtowane brwi.",
        classic_henna:
          "Henna klasyczna jest zabiegiem polecanym dla osób, które borykają się z jasnymi włoskami lub słabo zarysowanym łukiem brwiowym. Ta tradycyjna metoda barwienia cieszy się zainteresowaniem wśród klientek pragnących uzyskać efekt trwający dłużej niż po drogeryjnych kosmetykach przeznaczonych do brwi (kredki, pomady, żele barwiące). Henna klasyczna farbuje głównie sam włos.",
        powder_henna:
          "Henna pudrowa to metoda stylizacji, która pozwala osiągnąć nie tylko wyraziste i pięknie ubarwione brwi, ale również perfekcyjny kształt. Przeznaczona jest dla osób, które borykają się ze słabymi, pozbawionymi blasku, siwymi, lub rzadkimi brwiami. Jest to wspaniałe rozwiązanie także dla osób, które posiadają ubytki w brwiach (spowodowane np. chorobami autoimmunologicznymi).",
        eyebrow_makeup:
          "Makijaż permanentny brwi, nazywany również mikropigmentacją to zabieg polegający na wprowadzeniu pigmentu pod skórę w celu trwałego ubarwienia skóry i podkreślenia łuku brwiowego.<br /><br /> W przeciwieństwie do tradycyjnego makijażu, który wymaga codziennego nakładania i zmywania. Makijaż permanentny brwi gwarantuje efekt, który utrzymuje się przez wiele miesięcy lub lat, w zależności od rodzaju skóry, indywidualnych preferencji, pielęgnacji i pigmentów użytych do zabiegu. Makijaż permanentny brwi jest odporny na wodę i pot, co jest szczególnie korzystne również dla osób prowadzących aktywny tryb życia.",
        lip_makeup:
          "Makijaż permanentny ust, nazywany również mikropigmentacją to zabieg, który pozwala na uzyskanie trwałego i pięknie podkreślonego kształtu oraz koloru ust. Jest to świetna opcja dla osób, które na co dzień używają szminki, ponieważ ich naturalny kolor nie jest zadowalający lub z całych sił starają się optycznie powiększyć usta.",
        eyeliner_makeup:
          "Permanentny eyeliner to zabieg, który pozwala na uzyskanie trwałego i pięknie podkreślonego wyglądu oczu. Efekty, które uzyskujemy eliminują potrzebę codziennego rysowania kreski na powiece, zagęszczają linię rzęs, optycznie powiększają oko, a także zapewniają perfekcyjny wygląd o każdej porze dnia! ",
        makeup_removal:
          "Makijaż permanentny jest obecnie jednym z najbardziej pożądanych zabiegów w gabinetach kosmetycznych.  Jednak wraz z jego popularnością, wzrosło również zapotrzebowanie na zabieg odwrotny, czyli usuwanie makijażu permanentnego.",
        laser_remover:
          "Nieudany makijaż permanentny potrafi znacząco wpłynąć na wygląd i samopoczucie. Na szczęście można się go skutecznie pozbyć. Mamy dwie możliwości Laser lub Remover.<br /><br /> Co wybrać?<br /><br /> Obie metody są bezpieczne, a także skuteczne. W jednym przypadku lepiej poradzi sobie laser , a w innym trzeba poprosić o pomoc remover.  Laseroterapia wykorzystuje moc promieniowania laserowego, aby rozbić cząsteczki pigmentu umieszczonego w skórze, natomiast Remover podawany jest podobnie do klasycznej pigmentacji. Preparat ma za zadanie wydobyć pigment ze skóry na zewnątrz.",
        detox_peel: `<span class="font-medium">Detox Peel</span> to zabieg, którego efektem jest głębokie oczyszczenie skóry twarzy, nawilżenie oraz regeneracja naskórka.<br /><br /> Detox Peel to zabieg, który odpowiada na wszystkie potrzeby skóry - nawet tej wyjątkowo problematycznej (np. skóra palacza). Działa oczyszczająco, rewitalizująco, a także odświeżająco.`,
        chemical_peeling:
          "Peelingi chemiczne to skuteczna metoda poprawy kondycji oraz wyglądu skóry. W zależności od zastosowanego peelingu możemy uzyskać efekt oczyszczający, regenerujący, rozjaśniający, przeciwzmarszczkowy lub odmładzający.<br /><br /> Podczas zabiegu z użyciem peelingów chemicznych podstawą jest dostosowanie peelingu do potrzeb skóry. Z zabiegu z powodzeniem mogą korzystać osoby z cerą dojrzałą, trądzikową, naczynkową, a także osoby, które nie mają problemów ze skórą twarzy, a pragną jedynie zregenerować naskórek.",
        tissue_stimulator: `Stymulatory tkankowe to nowoczesne rozwiązanie w dziedzinie medycyny estetycznej, którego celem jest osiągnięcie <span     class="font-medium"> zdrowego wyglądu skóry.</span> Z wiekiem kondycja naszej skóry ulega pogorszeniu - pojawiają się zmarszczki, bruzdy oraz widoczna utrata jędrności i elastyczności, dzięki stymulatorom tkankowym możemy nie tylko spowolnić proces starzenia, ale przede wszystkim znacznie poprawić wygląd i stan naszej skóry.<br /><br /> W naszym gabinecie stymulatory tkankowe wykonujemy zarówno na skórze twarzy, jak i całym ciele.`,
        mesotherapy: `Mezoterapia igłowa to jeden z najskuteczniejszych zabiegów z zakresu medycyny estetycznej, który wykazuje działanie anti-aging. Zabieg stosuje się zarówno profilaktycznie w celu zmniejszenia objawów starzenia się skóry,  jak i odpowiedź na występujące przebarwienia, czy też zmatowioną, zmęczoną skórę twarzy.<br /><br /> Mezoterapia igłowa przynosi natychmiastowy efekt rozjaśnionej, świetlistej cery, ujednolica koloryt, wygładza zmarszczki, nawilża, a także rewitalizuje. Skóra staje się bardziej sprężysta, jędrna, a także widocznie młodsza. Wysoka skuteczność zabiegu i dobór odpowiedniego koktajlu pozwala na stosowanie mezoterapii igłowej zarówno na skórze twarzy, jak i na ciele oraz skórze głowy.`,
        lip_modeling:
          "Modelowanie czy powiększanie ust kwasem hialuronowym to zabieg naturalny, a także najczęściej wykonywany w gabinetach medycyny estetycznej. Naturalne powiększanie ust możliwe jest do zastosowania w przypadku większości osób. Umożliwia to uzyskanie pożądanych efektów w odpowiedzi na brak satysfakcji z kształtu ust czy ich struktury.<br /><br /> Bardzo często niezadowalający kształt czy struktura ust spowodowane są uwarunkowaniem genetycznym. Mamy wówczas do czynienia z asymetrią lub małą objętością.<br /><br /> Modelując usta, specjalista może zastosować różne techniki podania kwasu hialuronowego. Dobiera się je jednak mocno indywidualnie, uwzględniając preferencje, oczekiwania, ale przede wszystkim naturalny kształt ust, mimikę i proporcje twarzy. Każdy z nas jest przecież inny!<br /><br /> Najpopularniejszą metodą aktualnie jest modelowanie ust na płasko. ",
        lipolysis: `Lipoliza iniekcyjna to zabieg umożliwiający modelowanie sylwetki, a także redukcję nagromadzonego tłuszczu w wybranych obszarach ciała.`,
        botulinum_toxin: `Toksyna botulinowa - szerzej znana jako botoks, cechuje się właściwościami upiększającymi oraz ujędrniającymi. Zabiegi z użyciem botoksu to jednak nie tylko powiew młodości, ponieważ substancja ta ma również właściwości lecznicze - redukuje migreny, a także wspiera leczenie bruksizmu.<br /><br /> W kosmetologii jednak botoks pozwala na ekspresowe <span class="font-medium">wygładzenie zmarszczek mimicznych i poprawę wyglądu twarzy, dając efekt młodszej i bardziej promiennej cery.</span> Zabiegi najczęściej wykonujemy na górnych partiach twarzy, tj: linie poziome czoła, lwią zmarszczkę, czy też kurze łapki.`,
      };
      return treatmentTextMap[section] || "";
    },
    getImage(section) {
      const imageMap = {
        lashes_styling: require("@/assets/slide4.jpg"),
        non_standard_lashes_styling: require("@/assets/non_standard_lashes_styling.jpg"),
        lashes_lamination: require("@/assets/lashes_lamination.jpg"),
        eyebrow_lamination: require("@/assets/eyebrow_lamination.jpg"),
        classic_henna: require("@/assets/classic_henna.png"),
        powder_henna: require("@/assets/powder_henna.jpg"),
        eyebrow_makeup: require("@/assets/eyebrow_makeup.jpg"),
        lip_makeup: require("@/assets/lip_makeup.jpg"),
        eyeliner_makeup: require("@/assets/eyeliner_makeup.jpg"),
        makeup_removal: require("@/assets/makeup_removal.jpg"),
        laser_remover: require("@/assets/laser_remover.jpg"),
        detox_peel: require("@/assets/detox_peel.jpg"),
        chemical_peeling: require("@/assets/chemical_peeling.jpg"),
        mesotherapy: require("@/assets/mesotherapy.png"),
        tissue_stimulator: require("@/assets/tissue_stimulator.png"),
        lip_modeling: require("@/assets/lip_modeling.jpg"),
        lipolysis: require("@/assets/lipolysis.jpg"),
        botulinum_toxin: require("@/assets/botulinum_toxin.jpg"),
      };
      return imageMap[section] || require("@/assets/slide4.jpg");
    },
  },
};
</script>
<style scoped></style>
