<template>
  <section id="contact" class="-mt-24 h-auto pt-24 md:h-screen">
    <div
      class="relative h-96 md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2"
    >
      <img
        class="h-full w-full object-cover md:h-full"
        src="@/assets/contact.png"
        alt="Profil właścicielki salonu trzymającej w ręku teczkę"
      />
    </div>
    <div class="relative mx-auto max-w-7xl pt-20 sm:pt-20 lg:px-8">
      <div
        class="pl-4 pr-4 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32"
      >
        <h2
          class="border-b border-papi-gold-200 text-center font-dm-serif-display text-2xl font-normal tracking-wide text-papi-black-100 sm:text-4xl md:text-left lg:text-6xl"
        >
          Kontakt
        </h2>
        <dl class="mt-10 text-base font-light text-papi-black-100 md:mt-16">
          <div>
            <dt class="sr-only">Address</dt>
            <dd class="flex items-center justify-start">
              <i
                class="fa-sharp fa-solid fa-location-dot text-papi-black-100"
              />
              <span class="ml-3">ul. Światowida 47B lok użytkowy 2</span>
            </dd>
            <dd class="ml-6">03-144 Warszawa</dd>
          </div>
          <div class="mt-2">
            <dt class="sr-only">Address 2</dt>
            <dd class="flex items-center justify-start">
              <i
                class="fa-sharp fa-solid fa-location-dot text-papi-black-100"
              />
              <span class="ml-3">ul. Światowida 47 lok użytkowy 14</span>
            </dd>
            <dd class="ml-6">03-144 Warszawa</dd>
          </div>
          <div class="mt-2">
            <dt class="sr-only">Phone number</dt>
            <dd class="flex items-center justify-start">
              <i class="fa-solid fa-phone text-papi-black-100" />
              <span class="ml-3"> 517-742-895 </span>
            </dd>
          </div>
          <div class="mt-2">
            <dt class="sr-only">Email</dt>
            <dd class="flex items-center justify-start text-papi-black-100">
              <i class="fa-regular fa-envelope" />
              <a
                href="mailto:
pracowniarzes@gmail.com"
                class="ml-3 text-sm sm:text-base"
              >
                pracowniarzes@gmail.com</a
              >
            </dd>
          </div>
        </dl>
        <object class="mb-16 mt-10 md:mb-0">
          <iframe
            title="Mapa z lokalizacją pracowni PAPI"
            sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
            allow="geolocation"
            referrerpolicy="no-referrer"
            width="350"
            height="300"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            id="gmap_canvas"
            src="https://maps.google.com/maps?width=450&amp;height=350&amp;hl=en&amp;q=%C5%9Awiatowida%2047B%20lok%20u%C5%BCytkowy%202%20Warszawa+()&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </object>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Contact",
};
</script>

<style scoped></style>
