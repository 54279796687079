<template>
  <section
    class="z-40 flex w-full items-center justify-center px-2 py-6 lg:px-4 lg:py-28"
  >
    <Carousel :settings="settings" :items-to-show="2.5" class="w-full">
      <Slide v-for="slide in slides" :key="slide.id">
        <div
          class="mx-1 flex h-60 w-full content-center items-center lg:mx-2 lg:h-600"
        >
          <img
            :src="slide.image"
            :alt="slide.alt"
            class="rounded-sm shadow-sm"
          />
        </div>
      </Slide>
      <template #addons>
        <Navigation>
          <template #next>
            <button
              type="button"
              title="Nawiguj w prawo do następnego zdjęcia"
              class="focus:outline-none -mr-2 inline-flex items-center rounded-sm bg-papi-pink-300 p-1 text-white shadow-sm transition duration-150 ease-in-out hover:bg-papi-pink-200 md:mr-4"
            >
              <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </template>
          <template #prev>
            <button
              type="button"
              title="Nawiguj w lewo do poprzedniego zdjęcia"
              class="focus:outline-none -ml-2 inline-flex items-center rounded-sm bg-papi-pink-300 p-1 text-white shadow-sm transition duration-150 ease-in-out hover:bg-papi-pink-200 md:ml-4"
            >
              <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </template>
        </Navigation>
      </template>
    </Carousel>
  </section>
</template>

<script>
import { Carousel, Slide, Navigation } from "vue3-carousel";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/vue/outline";
import "vue3-carousel/dist/carousel.css";

const slides = [
  {
    id: "1",
    alt: "Twarz kobiety o rudych włosach z zamkniętymi oczami, eksponującymi długie rzęsy",
    image: require("@/assets/slide2.jpg"),
  },
  {
    id: "2",
    alt: "Wnętrze salonu PAPI w różowo-złotej kolorystyce. Zdjęcie ukazuje poczekalnię z sofą oraz odzielone miejsce wykonywania zabiegów.",
    image: require("@/assets/salon1.jpeg"),
  },
  {
    id: "3",
    alt: "Kobieta z upiętymi włosami i lekko przymkniętymi oczami. Eksponuje różowe paznokcie i subtelny, dziewczęcy makijaż",
    image: require("@/assets/slide5.jpg"),
  },
  {
    id: "4",
    alt: "Wnętrze salonu PAPI w różowo-złotej kolorystyce. Zdjęcie ukazuje hall z recepcją oraz drzwi wejściowe do salonu.",
    image: require("@/assets/salon2.jpeg"),
  },

  {
    id: "5",
    alt: "Twarz kobiety w dużym zbliżeniu na oczy podczas zabiegu kosmetycznego",
    image: require("@/assets/slide6.jpg"),
  },
];

export default {
  name: "Slider",
  components: {
    Carousel,
    Slide,

    Navigation,
    ChevronRightIcon,
    ChevronLeftIcon,
  },
  setup() {
    return {
      slides,
      settings: {
        itemsToShow: 1.5,
        wrapAround: true,
        autoplay: "5000",
      },
    };
  },
};
</script>

<style scoped></style>
