<template>
  <Disclosure
    as="nav"
    class="sticky top-0 z-50 bg-white shadow-md blur-sm"
    v-slot="{ open }"
  >
    <div class="mx-auto max-w-7xl px-4 md:px-6 lg:px-8">
      <div
        class="border-papi-white-100 flex h-20 items-center justify-between border-b sm:h-32 md:h-28 md:border-transparent"
      >
        <div class="px-2 py-4 sm:py-0">
          <router-link :to="{ name: 'Home' }" @click="scrollToTop">
            <img
              class="h-10 flex-shrink-0 md:h-18"
              src="../assets/papi_logo_vertical.png"
              alt="Logo Pracowni PaPi"
            />
          </router-link>
        </div>
        <div class="flex md:justify-between">
          <div class="hidden md:flex md:space-x-8">
            <Menu as="div" class="relative">
              <div>
                <MenuButton
                  @click="isOpen = !isOpen"
                  type="button"
                  class="focus:outline-none inline-flex cursor-pointer items-center pr-2 pt-1 text-lg font-light text-papi-black-100 transition duration-150 ease-in-out hover:text-papi-gold-200 lg:pr-8"
                >
                  <span class="sr-only">Otwórz sekcję zabiegi</span>
                  Zabiegi
                  <i class="fa fa-thin fa-chevron-down ml-1 text-xs" />
                </MenuButton>
              </div>

              <MenuItems
                :class="!isOpen ? 'block' : 'hidden'"
                class="absolute left-9 start-0 top-8 z-50 w-80 space-y-0.5 bg-white py-2 shadow-md blur-sm"
              >
                <DisclosureButton
                  :class="!isOpen ? 'block' : 'hidden'"
                  v-for="section in [
                    'lashes_styling',
                    'non_standard_lashes_styling',
                    'lashes_lamination',
                    'eyebrow_lamination',
                    'classic_henna',
                    'powder_henna',
                    'eyebrow_makeup',
                    'lip_makeup',
                    'eyeliner_makeup',
                    'makeup_removal',
                    'laser_remover',
                    'detox_peel',
                    'chemical_peeling',
                    'mesotherapy',
                    'tissue_stimulator',
                    'lip_modeling',
                    'lipolysis',
                    'botulinum_toxin',
                  ]"
                  :key="section"
                  as="a"
                  :href="`/#${section}`"
                  @click="
                    $store.commit('TOGGLE_DISPLAY_' + section.toUpperCase());
                    $store.commit('SET_ACTIVE_SECTION', section);
                    isOpen = !isOpen;
                  "
                  class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-sm font-light text-papi-black-100 hover:border-gray-300 hover:bg-gray-50 hover:text-papi-gray-300"
                >
                  {{ getButtonText(section) }}
                </DisclosureButton>
              </MenuItems>
            </Menu>

            <router-link
              :to="{ name: 'PriceList' }"
              type="link"
              class="inline-flex items-center px-2 pt-1 text-lg font-light text-papi-black-100 transition duration-150 ease-in-out hover:text-papi-gold-200 lg:px-8"
            >
              Cennik
            </router-link>

            <a
              href="/#contact"
              type="button"
              class="inline-flex items-center pl-2 pt-1 text-lg font-light text-papi-black-100 transition duration-150 ease-in-out hover:text-papi-gold-200 lg:pl-8"
            >
              Kontakt
            </a>
          </div>
        </div>
        <div class="hidden lg:ml-6 lg:flex lg:items-center">
          <div class="flex-shrink-0">
            <a
              href="https://www.facebook.com/rzesypapi"
              aria-label="Idź do profilu pracowni PaPi na Facebook"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i
                class="fa-brands fa-facebook-f text-papi-black-100 hover:text-papi-gold-200"
              ></i>
            </a>
            <a
              href="https://instagram.com/pracownia_papi?igshid=YmMyMTA2M2Y="
              aria-label="Idź do profilu pracowni PaPi na Instagram"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i
                class="fa-brands fa-instagram mx-4 text-papi-black-100 hover:text-papi-gold-200"
              ></i>
            </a>
            <a
              href="https://m.me/rzesypapi"
              aria-label="Napisz do pracowni PaPi przez chat na Messenger"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i
                class="fa-brands fa-facebook-messenger text-papi-black-100 hover:text-papi-gold-200"
              ></i>
            </a>
          </div>
        </div>
        <div class="-mr-2 flex items-center md:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="focus:outline-none inline-flex items-center justify-center rounded-md p-2 text-papi-black-100 transition duration-150 ease-in-out hover:bg-papi-gold-100 hover:text-papi-gold-200"
          >
            <span class="sr-only">Otwórz główne menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="md:hidden">
      <div class="space-y-1 pb-2 pt-2 md:pb-4">
        <Disclosure as="div" v-slot="{ isOpenMobile }">
          <dt>
            <DisclosureButton
              class="flex w-full items-center justify-start border-l-4 border-transparent py-2 pl-3 pr-4 text-sm font-light text-papi-black-100 transition duration-150 ease-in-out hover:border-gray-300 hover:bg-gray-50 hover:text-papi-gray-300"
            >
              <span class="sr-only">Otwórz sekcję zabiegi</span>
              Zabiegi
              <i
                v-if="!isOpenMobile"
                class="fa fa-thin fa-chevron-down ml-1 mt-0.5 text-xs"
                aria-hidden="true"
              />
            </DisclosureButton>
          </dt>
          <DisclosurePanel as="dd" class="w-full space-y-1 py-4">
            <DisclosureButton
              v-for="section in [
                'lashes_styling',
                'non_standard_lashes_styling',
                'lashes_lamination',
                'eyebrow_lamination',
                'classic_henna',
                'powder_henna',
                'eyebrow_makeup',
                'lip_makeup',
                'eyeliner_makeup',
                'makeup_removal',
                'laser_remover',
                'detox_peel',
                'chemical_peeling',
                'mesotherapy',
                'tissue_stimulator',
                'lip_modeling',
                'lipolysis',
                'botulinum_toxin',
              ]"
              :key="section"
              as="a"
              :href="`/#${section}`"
              @click="
                $store.commit('TOGGLE_DISPLAY_' + section.toUpperCase());
                $store.commit('SET_ACTIVE_SECTION', section);
              "
              class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-sm font-light text-papi-black-100 hover:border-gray-300 hover:bg-gray-50 hover:text-papi-gray-300"
            >
              {{ getButtonText(section) }}
            </DisclosureButton>
          </DisclosurePanel>
        </Disclosure>
        <DisclosureButton
          as="a"
          href="/cennik"
          type="button"
          class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-sm font-light text-papi-black-100 transition duration-150 ease-in-out hover:border-gray-300 hover:bg-gray-50 hover:text-papi-gray-300"
          >Cennik</DisclosureButton
        >

        <DisclosureButton
          as="a"
          href="/#contact"
          type="button"
          class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-sm font-light text-papi-black-100 transition duration-150 ease-in-out hover:border-gray-300 hover:bg-gray-50 hover:text-papi-gray-300"
          >Kontakt</DisclosureButton
        >
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

import { MenuIcon, XIcon } from "@heroicons/vue/outline";

export default {
  name: "NavBar",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuIcon,
    XIcon,
  },
  data() {
    return {
      isOpen: true,
      isOpenMobile: true,
    };
  },
  methods: {
    getButtonText(section) {
      const buttonTextMap = {
        lashes_styling: "Stylizacja rzęs",
        non_standard_lashes_styling: "Niestandardowe metody stylizacji rzęs",
        lashes_lamination: "Laminacja rzęs",
        eyebrow_lamination: "Laminacja brwi",
        classic_henna: "Henna klasyczna brwi",
        powder_henna: "Henna pudrowa brwi",
        eyebrow_makeup: "Makijaż permanentny brwi",
        lip_makeup: "Makijaż permanentny ust",
        eyeliner_makeup: "Eyeliner permanentny",
        makeup_removal: "Usuwanie makijażu permanentnego",
        laser_remover: "Laser/ Remover",
        detox_peel: "Detox Peel",
        chemical_peeling: "Peelingi chemiczne",
        mesotherapy: "Mezoterapia igłowa",
        tissue_stimulator: "Stymulatory tkankowe",
        lip_modeling: "Modelowanie ust na płasko",
        lipolysis: "Lipoloza iniekcyjna",
        botulinum_toxin: "Toksyna botulinowa",
      };
      return buttonTextMap[section] || "";
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped></style>
